<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-catalog"
    ref="modal-catalog"
    :title="principal_Id ? `Edit ${catalogName}` : `New ${catalogName}` "
    no-close-on-backdrop
    :hide-footer="true"
    centered
    @hidden="resetModal"
  >
    <div>
      <b-form-group
        v-show="Model.Name.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Name.text"
        label-for="input-sm"
      >
        <b-form-input
          id="input-sm"
          v-model="Model.Name.value"
          size="sm"
          placeholder="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.LastName.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.LastName.text"
        label-for="input-sm"
      >
        <b-form-input
          id="input-sm"
          v-model="Model.LastName.value"
          size="sm"
          placeholder="Last Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.Description.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Description.text"
        label-for="input-sm"
      >
        <b-form-textarea
          id="textarea-rows"
          v-model="Model.Description.value"
          placeholder="Description textarea"
          rows="3"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.SingleSelect1.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.SingleSelect1.text"
        label-for="input-sm"
      >
        <v-select
          v-model="Model.SingleSelect1.value"
          class="select-size-sm"
          :options="optionSingle1"
          label="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.SingleSelect2.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.SingleSelect2.text"
        label-for="input-sm"
      >
        <v-select
          v-model="Model.SingleSelect2.value"
          class="select-size-sm"
          :options="optionSingle2"
          label="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.MultipleSelect1.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.MultipleSelect1.text"
        label-for="input-sm"
      >
        <v-select
          v-model="Model.MultipleSelect1.value"
          multiple
          class="select-size-sm"
          :options="optionMultiple1"
          label="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.MultipleSelect2.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.MultipleSelect2.text"
        label-for="input-sm"
      >
        <v-select
          v-model="Model.MultipleSelect2.value"
          multiple
          class="select-size-sm"
          :options="optionMultiple2"
          label="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.Time1.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Time1.text"
        label-for="input-sm"
      >
        <b-form-timepicker
          v-model="Model.Time1.value"
          locale="en"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.Time2.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Time2.text"
        label-for="input-sm"
      >
        <b-form-timepicker
          v-model="Model.Time2.value"
          locale="en"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.InputNumer1.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.InputNumer1.text"
        label-for="input-sm"
      >
        <b-form-input
          id="input-sm"
          v-model="Model.InputNumer1.value"
          type="number"
          size="sm"
          placeholder="Input Number 1"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.InputNumer2.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.InputNumer2.text"
        label-for="input-sm"
      >
        <b-form-input
          id="input-sm"
          v-model="Model.InputNumer2.value"
          type="number"
          size="sm"
          placeholder="Input Number 2"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.Date.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Date.text"
        label-for="input-sm"
      >
        <flat-pickr
          v-model="Model.Date.value"
          class="form-control"
        />
      </b-form-group>
      <b-form-group
        v-if="Model.AvailableDays.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.AvailableDays.text"
        label-for="input-sm"
      >
        <b-form-checkbox-group
          v-model="Model.AvailableDays.value"
          :options="optionsDays"
          name="flavour-1a"
          class="demo-inline-spacing"
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
        @click="CreateUpdateElement()"
      >
        {{ principal_Id ? `Edit` : `Save` }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BFormGroup, BFormInput, BFormTextarea, BFormTimepicker, BButton, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import catalogServices from '@core/services/catalog/catalogService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormTimepicker,
    flatPickr,
    BFormCheckboxGroup,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      catalogName: '',
      principal_Id: 0,
      optionSingle1: [],
      optionSingle2: [],
      optionMultiple1: [],
      optionMultiple2: [],
      Model: {
        Name: { active: false, value: null, text: '' }, LastName: { active: false, value: null, text: '' }, Description: { active: false, value: null, text: '' }, SingleSelect1: { active: false, value: null, text: '' }, SingleSelect2: { active: false, value: null, text: '' }, MultipleSelect1: { active: false, value: null, text: '' }, MultipleSelect2: { active: false, value: null, text: '' }, Time1: { active: false, value: null, text: '' }, Time2: { active: false, value: null, text: '' }, InputNumer1: { active: false, value: null, text: '' }, InputNumer2: { active: false, value: null, text: '' }, Date: { active: false, value: null, text: '' }, AvailableDays: { active: false, value: [], text: '' },
      },
      Selected: [],
      optionsDays: [
        { text: 'SU', value: 'SU' },
        { text: 'MO', value: 'MO' },
        { text: 'TU', value: 'TU' },
        { text: 'WE', value: 'WE' },
        { text: 'TH', value: 'TH' },
        { text: 'FR', value: 'FR' },
        { text: 'SA', value: 'SA' },
      ],
      // Name: { active: false, value: null, text: '' }, LastName: { active: false, value: null, text: '' }, Description: { active: false, value: null, text: '' }, SingleSelect1: { active: false, value: null, text: '' }, SingleSelect2: { active: false, value: null, text: '' }, MultipleSelect1: { active: false, value: null, text: '' }, MultipleSelect2: { active: false, value: null, text: '' }, Time1: { active: false, value: null, text: '' }, Time2: { active: false, value: null, text: '' }, InputNumer1: { active: false, value: null, text: '' }, InputNumer2: { active: false, value: null, text: '' }, Date: { active: false, value: null, text: '' },
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  created() {
    this.$bus.$on('setParamsModalCatalog', param => {
      const { catalog, data } = param
      this.resetModal()
      this.SetData(catalog, data)
      this.catalogName = catalog
      if (!data) {
        this.principal_Id = 0
      } else {
        this.principal_Id = data.Id
      }
    })
  },
  methods: {
    resetModal() {
      this.Model = {
        Name: { active: false, value: null, text: '' }, LastName: { active: false, value: null, text: '' }, Description: { active: false, value: null, text: '' }, SingleSelect1: { active: false, value: null, text: '' }, SingleSelect2: { active: false, value: null, text: '' }, MultipleSelect1: { active: false, value: null, text: '' }, MultipleSelect2: { active: false, value: null, text: '' }, Time1: { active: false, value: null, text: '' }, Time2: { active: false, value: null, text: '' }, InputNumer1: { active: false, value: null, text: '' }, InputNumer2: { active: false, value: null, text: '' }, Date: { active: false, value: null, text: '' }, AvailableDays: { active: false, value: [], text: '' },
      }
    },
    async SetData(catalog, data) {
      switch (catalog) {
        case 'SubCategory':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.SingleSelect1.active = true
          this.Model.SingleSelect1.text = 'Category'
          this.optionSingle1 = await this.reloadSelect('Category')
          if (data) {
            this.Model.Name.value = data.Name
            this.Model.SingleSelect1.value = { Id: data.IdCategory, Name: data.Category }
          }
          break
        case 'SubSubCategory':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.SingleSelect1.active = true
          this.Model.SingleSelect1.text = 'SubCategory'
          this.optionSingle1 = await this.reloadSelect('SubCategory')
          if (data) {
            this.Model.Name.value = data.Name
            this.Model.SingleSelect1.value = { Id: data.IdSubCategory, Name: data.SubCategory }
          }
          break
        default:
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          if (data) {
            this.Model.Name.value = data.Name
          }
          break
      }
    },
    async reloadSelect(catalog) {
      return catalogServices.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const selectoption = response.data.data
        return selectoption
      })
    },
    /* eslint-disable */
    Valid() {
      let Text = ''
      for (const obj in this.Model) {
        if (this.Model[obj].active === true) {
          if (!this.Model[obj].value || this.Model[obj].value === null || this.Model[obj].value.length === 0) {
            Text += `-${this.Model[obj].text} missing<br>`
          }
        }
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Profile Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
        return false
      } else {
        return true
      }
    },
    /* eslint-enable */
    CreateUpdateElement() {
      const complete = this.Valid()
      if (complete) {
        Swal.fire({
          title: 'Attention',
          text: `¿Do you want to ${this.principal_Id ? 'edit' : 'save'} this element?`,
          icon: 'warning',
          confirmButtonText: 'Yes',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        }).then(result => {
          if (result.isConfirmed) {
            this.editcreateElement()
          }
        })
      }
    },
    toastSuccessEdit(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          text: message.data,
          icon: 'CheckSquareIcon',
          variant: 'success',
        },
      })
    },
    editcreateElement() {
      catalogServices.createUpdateElement({
        table: this.catalogName,
        id: this.principal_Id,
        name: this.Model.Name.value,
        idCategory: this.Model.SingleSelect1.active ? this.Model.SingleSelect1.value.Id : null,
      }).then(response => {
        this.toastSuccessEdit(response.data)
        this.refreshHandler(this.catalogName)
        this.$root.$emit('bv::hide::modal', 'modal-catalog')
      })
        .catch(error => {
          this.toastErrorHandler(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
