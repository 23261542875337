<template>
  <div>
    <b-card>
      <b-tabs
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
        vertical
      >
        <!-- general tab -->
        <b-tab
          v-for="(catalog, index) in Object.keys(catalogLists)"
          :key="catalogLists[catalog].table"
          :index="index"
        >
          <template #title>
            <span
              class="font-weight-bold"
              style="font-size: 12px !important;"
            > {{ catalog }} </span>
          </template>
          <table-catalog
            :table-name="catalog"
            :data-list="catalogLists[catalog]"
            :refresh-handler="getSpecificCatalog"
          />
        </b-tab>
        <!-- general tab -->
      </b-tabs>
    </b-card>
    <modal-catalog
      :refresh-handler="getSpecificCatalog"
    />
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import catalogsServices from '@core/services/catalog/catalogService'
import TableCatalog from './TableCatalog.vue'
import ModalCatalog from './Modal/CatalogsModal.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    TableCatalog,
    ModalCatalog,
  },
  data() {
    return {
      catalogLists: {
        Category: [],
        Kind: [],
        Priority: [],
        Project: [],
        SubCategory: [],
        SubSubCategory: [],
      },
      SideBarinfo: {},
    }
  },
  mounted() {
  },
  created() {
    this.getAllCatalogs()
  },
  methods: {
    getAllCatalogs() {
      const listCatalogs = Object.keys(this.catalogLists)
      listCatalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    /* eslint-disable */
    getSpecificCatalog(catalog) {
      catalogsServices.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const data = response.data.data
        this.catalogLists[catalog] = data
      })
    },

    sendInfoByCatalogs(info) {
      this.SideBarinfo = info
    }
    /* eslint-enable */
  },

}
</script>

<style>

</style>
